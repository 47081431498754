// hygen:import
import lang from "./lang";
import isSmallMobile from "./isSmallMobile";
import isMobile from "./isMobile";
import isTablet from "./isTablet";
import isBigTablet from "./isBigTablet";
import isLaptop from "./isLaptop";
import isDesktop from "./isDesktop";
import { combineReducers } from "redux";

export const Application = combineReducers({
  // hygen:export
  lang,
  isSmallMobile,
  isMobile,
  isTablet,
  isBigTablet,
  isLaptop,
  isDesktop
});
