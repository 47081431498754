import 'core-js/stable'
import 'regenerator-runtime/runtime'
import './components/Application/less/index.less'
import React from 'react'
import ReactDOM from 'react-dom'
import Application from 'Application'
import smoothScroll from 'smoothscroll-polyfill'

smoothScroll.polyfill()

document.addEventListener('DOMContentLoaded', () => {
	document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
		anchor.addEventListener('click', function(e) {
			e.preventDefault()
			const selector = this.getAttribute('href')
			if (selector !== '#') {
				document.querySelector(this.getAttribute('href')).scrollIntoView({
					behavior: 'smooth'
				})
			}
		})
	})
})

ReactDOM.render(
	<Application />,
	document.getElementById('app')
)
