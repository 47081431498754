import styled from "styled-components";

const Overlay = styled.div`
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  background: #000000;
  opacity: 0.3;
`;

export default Overlay;
