import styled from "styled-components";

const calc = (gutter, multiplier) => {
  if (!isNaN(gutter) && !isNaN(multiplier)) {
    return gutter * multiplier + "px";
  } else {
    return undefined;
  }
};

const Root = styled.div`
  ${({
    gutter,
    m,
    mb,
    mt,
    ml,
    mr,
    mx,
    my,
    p,
    py,
    px,
    pt,
    pr,
    pb,
    pl,
    df,
    dif,
    ma,
    ca,
    dir,
    flex,
    order,
    fg,
    fs,
    fw,
    fb,
    ff,
    as,
    ac,
    w,
    h,
    pos
  }) => {
    let margin = undefined;
    let padding = undefined;

    if (!isNaN(mx) || !isNaN(my)) {
      const xAxis = !isNaN(mx) ? calc(gutter, mx) : "auto";
      const yAxis = !isNaN(my) ? calc(gutter, my) : "auto";
      margin = `${yAxis} ${xAxis} `;
    }

    if (!isNaN(m)) {
      margin = calc(gutter, m);
    }

    if (!isNaN(px) || !isNaN(py)) {
      const xAxis = !isNaN(px) ? calc(gutter, px) : "0";
      const yAxis = !isNaN(py) ? calc(gutter, py) : "0";
      padding = `${yAxis} ${xAxis} `;
    }

    if (!isNaN(p)) {
      padding = calc(gutter, p);
    }

    const result = {
      margin,
      marginTop: calc(gutter, mt),
      marginRight: calc(gutter, mr),
      marginBottom: calc(gutter, mb),
      marginLeft: calc(gutter, ml),
      padding,
      paddingTop: calc(gutter, pt),
      paddingRight: calc(gutter, pr),
      paddingBottom: calc(gutter, pb),
      paddingLeft: calc(gutter, pl),
      width:
        typeof w === "string" ? w : !isNaN(w) ? calc(gutter, w) : undefined,
      height:
        typeof h === "string" ? h : !isNaN(h) ? calc(gutter, h) : undefined,
      position: pos
    };

    if (df) {
      result.display = "flex";
    } else if (dif) {
      result.display = "inline-flex";
    }
    if (ma) {
      result.justifyContent = ma;
    }
    if (ca) {
      result.alignItems = ca;
    }
    if (dir) {
      result.flexDirection = dir;
    }
    if (flex) {
      result.flex = flex;
    }
    if (order) {
      result.order = order;
    }
    if (fg) {
      result.flexGrow = fg;
    }
    if (fs) {
      result.flexShrink = fs;
    }
    if (fw) {
      result.flexWrap = fw;
    }
    if (fb) {
      result.flexBasis = fb;
    }
    if (ff) {
      result.flexFlow = ff;
    }
    if (as) {
      result.alignSelf = as;
    }
    if (ac) {
      result.alignContent = ac;
    }

    return result;
  }};
`;

export default Root;
