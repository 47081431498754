import defaultConf from './config.json'

let _config = defaultConf || {}

export function set(name = '', value = null) {
  if (name && typeof name === 'object') {
    _config = { ..._config, ...name }
  } else if (name && typeof name === 'string') {
    _config[name] = value
  }

  return _config
}

export function get(name = '') {
  if (typeof _config[name] !== 'undefined') {
    return _config[name]
  } else {
    return null
  }
}

export function api(name = '') {
  if (_config[name]) {
    return (_config.API_BASE || '/') + _config[name]
  } else {
    return '/'
  }
}

export function cdn(name = '') {
  if (_config[name]) {
    return (_config.CDN_BASE || '/') + _config[name]
  } else {
    return '/'
  }
}

export function proxy(name = '') {
  if (_config[name]) {
    return (_config.API_PROXY || '/') + _config[name]
  } else {
    return '/'
  }
}

export function toString() {
  return JSON.stringify(_config)
}

export function toJSON() {
  return { ..._config }
}

const functions = {
  set,
  get,
  api,
  cdn,
  proxy,
  toString,
  toJSON,
}

export default new Proxy(_config, {
  get: (target, prop) => {
    if (typeof functions[prop] === 'function') {
      return functions[prop]
    } else {
      return get(prop)
    }
  },
  set: (target, prop, value) => {
    set(prop, value)
  },
})

const allLanguages = ['en']
// const urlParams = (new URL(document.location)).searchParams
// const langUrlParam = urlParams.get('lang') === allLanguages[0] ? urlParams.get('lang') : null
const defaultLang = allLanguages[0]

export {
  allLanguages,
  defaultLang,
}
