import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Root from "atoms/Popup/styles/root";
import Overlay from "atoms/Popup/styles/overlay";
import ReactDOM from "react-dom";
import Content from "atoms/Popup/styles/content";

const Popup = ({ open, children }) => {
  const root = useRef(document.createElement("div"));

  useEffect(() => {
    document.body.appendChild(root.current);

    return () => {
      document.body.removeChild(root.current);
    };
  }, []);

  return open
    ? ReactDOM.createPortal(
        <Root>
          <Overlay />
          <Content>{children}</Content>
        </Root>,
        root.current
      )
    : null;
};

Popup.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.any
};

export default Popup;
