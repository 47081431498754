import React from "react";
import PropTypes from "prop-types";
import Root from "./styles/root";

const Box = ({
  gutter,
  m,
  mb,
  mt,
  ml,
  mr,
  mx,
  my,
  p,
  py,
  px,
  pt,
  pr,
  pb,
  pl,
  df,
  dif,
  ma,
  ca,
  dir,
  flex,
  order,
  fg,
  fs,
  fw,
  fb,
  ff,
  as,
  ac,
  children,
  w,
  h,
  pos,
  ...rest
}) => {
  return (
    <Root
      {...rest}
      df={df}
      dif={dif}
      ma={ma}
      ca={ca}
      dir={dir}
      flex={flex}
      order={order}
      fg={fg}
      fs={fs}
      fw={fw}
      fb={fb}
      ff={ff}
      as={as}
      ac={ac}
      gutter={gutter}
      m={m}
      mb={mb}
      mt={mt}
      ml={ml}
      mr={mr}
      mx={mx}
      my={my}
      p={p}
      py={py}
      px={px}
      pt={pt}
      pr={pr}
      pb={pb}
      pl={pl}
      w={w}
      h={h}
      pos={pos}
    >
      {children}
    </Root>
  );
};

Box.propTypes = {
  // display: flex
  df: PropTypes.bool,
  // display inline-flex
  dif: PropTypes.bool,
  // main axis
  ma: PropTypes.string,
  // cross axis
  ca: PropTypes.string,
  // flex-direction
  dir: PropTypes.string,
  // flex
  flex: PropTypes.string,
  // flex order
  order: PropTypes.number,
  // flex-grow
  fg: PropTypes.number,
  // flex-shrink
  fs: PropTypes.number,
  // flex-wrap
  fw: PropTypes.string,
  // flex-basis
  fb: PropTypes.string,
  // flex-flow
  ff: PropTypes.string,
  // align-self
  as: PropTypes.string,
  // align-content
  ac: PropTypes.string,
  gutter: PropTypes.number,
  m: PropTypes.number,
  mb: PropTypes.number,
  mt: PropTypes.number,
  ml: PropTypes.number,
  mr: PropTypes.number,
  mx: PropTypes.number,
  my: PropTypes.number,
  p: PropTypes.number,
  py: PropTypes.number,
  px: PropTypes.number,
  pt: PropTypes.number,
  pr: PropTypes.number,
  pb: PropTypes.number,
  pl: PropTypes.number,
  w: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  h: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.any
};

Box.defaultProps = {
  gutter: 8
};

export default Box;
