import React from 'react'
import Root from './styles/root'
import logo from '../../Application/img/logo.svg'

const Loader = ({ ...rest }) => {
  return (
    <Root {...rest}>
      <img src={logo} alt="Logo" width={170} />
    </Root>
  )
}

Loader.propTypes = {}

export default Loader
