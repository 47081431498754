import React, { Suspense, lazy } from "react";
import { Provider } from "react-redux";
import store from "../../store";
import { BrowserRouter, Switch, Route } from "react-router-dom";
const MainPage = lazy(() => import("./pages/main"));
const PrivacyPolicy = lazy(() => import("./pages/privacy_policy_doc"));
const Terms = lazy(() => import("./pages/terms_doc"));
const ByeLaws = lazy(() => import("./pages/bye_laws"));
const Fees = lazy(() => import("./pages/fees"));
import Loader from "../atoms/Loader";
import Popup from "atoms/Popup";
import Box from "atoms/Box";
import modal from "./less/modal/index.m.less";

const App = () => (
  <Suspense fallback={<Loader pageLoader={true} />}>
    <Provider store={store}>
      <Popup open>
        <div className={modal.root} style={{ maxWidth: "calc(100vw - 48px)" }}>
          <Box mb={2}>
            <h3>Fibo App Shutdown</h3>
          </Box>
          <Box mb={2}>
            We have decided to wind up our operations, consequently the Fibo
            mobile app and card will be discontinued. Please accept our
            apologies for any inconvenience and disruption this may cause.
          </Box>
          <Box mb={2}>
            All customers have been notified and advised via email and the Fibo
            app the steps to take until 6 November 2023 and thereafter, our
            services will no longer be available.
          </Box>
          <Box mb={2}>
            We are committed to making this transition as smooth as possible for
            you. If you have any clarifications and enquiries, kindly contact
            our support team via the app or send an email to <a href="mailto:info@joinfibo.com">info@joinfibo.com</a>.
          </Box>
          <Box mb={2}>
            Thank you
          </Box>
          <Box mb={2}>
            Team Fibo
          </Box>
        </div>
      </Popup>
      <div style={{ filter: "blur(7px)" }}>
        <BrowserRouter>
          <Switch>
            <Route path={"/"} exact>
              <MainPage />
            </Route>
            <Route path={"/terms_doc"} exact>
              <Terms />
            </Route>
            <Route path={"/privacy_policy_doc"} exact>
              <PrivacyPolicy />
            </Route>
            <Route path={"/bye_laws"} exact>
              <ByeLaws />
            </Route>
            <Route path={"/fees"} exact>
              <Fees />
            </Route>
          </Switch>
        </BrowserRouter>
      </div>
    </Provider>
  </Suspense>
);

export default App;
