import styled from 'styled-components'

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  
  ${({ pageLoader }) => {
    if (pageLoader) {
      return `
            height: 100vh;
          `
    }
  }}
`

export default Root
