export default {
  CHANGE_LANG: "changeLang",
  CHANGE_PAGE_TITLE: "changePageTitle",
  VIEWPORT_SMALL_MOBILE: "viewportSmallMobile",
  VIEWPORT_MOBILE: "viewportMobile",
  VIEWPORT_TABLET: "viewportTablet",
  VIEWPORT_BIG_TABLET: "viewportBigTablet",
  VIEWPORT_LAPTOP: "viewportLaptop",
  VIEWPORT_DESKTOP: "viewportDesktop"
};
