import createSagaMiddleware from 'redux-saga'
import { all } from 'redux-saga/effects'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import { applyMiddleware, combineReducers, createStore, compose } from 'redux'
// hygen:import
import { Application } from 'Application/reducers'

const sagaMiddleware = createSagaMiddleware()

// Create a history of your choosing (we're using a browser history in this case)
export const browserHistory = createBrowserHistory()
// Build the middleware for intercepting and dispatching navigation actions
const middleware = routerMiddleware(browserHistory)
// Add the reducer to your store on the `router` key
// Also apply our middleware for navigating
const composeEnhancers =
	      typeof window === 'object' &&
	      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
		      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
			      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
		      }) : compose

const enhancer = composeEnhancers(
	applyMiddleware(sagaMiddleware, middleware),
	// other store enhancers if any
)
const store = createStore(
	combineReducers({
		// hygen:reducers
		Application
	}),
	enhancer,
)

const rootSaga = function* () {
	yield all([
		// hygen:sagas
	])
}

sagaMiddleware.run(rootSaga)

export default store
