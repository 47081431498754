import actionTypes from '../actions/actionTypes'
import { defaultLang } from '../../../config'

export default function(state = defaultLang, action = {}) {
  if (action.type === actionTypes.CHANGE_LANG) {
    return action.value
  } else {
    return state
  }
}
